const Utils = {
  color: [
    'yellow',
    'blue',
    'green',
    'orange',
    'pink',
    'disco',
    'azure',
    'frost',
    'ocean',
    'flow',
  ]
}

export default Utils